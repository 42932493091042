import Error from 'next/error';

import { isServer } from '../utils/constants';
import { rollbar } from '../utils/rollbar';

class CustomError extends Error {}

CustomError.getInitialProps = ({ req, res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;

  if (isServer) {
    // Emit rollbar exception
    if (err != null) {
      rollbar?.error(err, req, (rollbarError) => {
        if (rollbarError) {
          console.error('Rollbar error reporting failed:');
          return;
        }

        console.log('Reported error to Rollbar');
      });
    }
  }

  return { statusCode: statusCode ?? 0 };
};

export default CustomError;
